import React from 'react'
import { Facebook, FiletypeGif, Instagram, Pinterest, SuitHeartFill, Tiktok, Youtube } from 'react-bootstrap-icons'
import SocialMedia from './SocialMedia/SocialMedia'
import styles from './SocialOptions.module.scss'
import TwitterX from './TwitterX'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import DonationBtn from '../DonationBtn/DonationBtn'

const SocialMediaOptions = [
    {
        icon: Facebook,
        link: 'https://www.facebook.com/texasarchive',
        color: '#3b5998'
    },
    {
        icon: Instagram,
        link: 'https://www.instagram.com/texasarchive/',
        color: 'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)'
    },
    /* {
        icon: Pinterest,
        link: 'https://www.pinterest.com/texasarchive/',
        color: '#bd081c'
    }, */
    {
        icon: Tiktok,
        link: 'https://www.tiktok.com/@texasarchive',
        color: '#000000'
    },
    {
        icon: TwitterX,
        link: 'https://twitter.com/texasarchive?lang=en',
        color: '#000000'
    },
    {
        icon: Youtube,
        link: 'https://www.youtube.com/user/texasarchive',
        color: '#ff0000'
    },
    {
        icon: FiletypeGif,
        link: 'https://giphy.com/texasarchive/',
        color: '#000000'
    },

]

export default function SocialOptions(props) {
    const { navbar } = props;
    const navigate = useNavigate();
    return (
        <div className={`${styles.options}`}>
            {
                SocialMediaOptions.map((option, index) => {
                    return (
                        <SocialMedia
                            key={index}
                            icon={option.icon}
                            link={option.link}
                            color={option.color}
                        />
                    )
                })
            }
            {
                navbar && (

                    <DonationBtn />
                )
            }
        </div>
    )
}
