import SocialOptions from 'modules/website/components/SocialOptions/SocialOptions';
import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { ArrowUp, EnvelopePaper, SuitHeartFill } from 'react-bootstrap-icons';
import styles from './Footer.module.scss';
import { Link, useNavigate } from 'react-router-dom';

const options = [
	[
		{
			title: 'Videos',
			link: '/videos',
		},
		{
			title: 'Film Round-Up',
			link: '/round-up',
		},
	],
	[
		{
			title: 'Web Exhibits',
			link: '/web-exhibits',
		},
		{
			title: 'Support Us',
			link: '/support-us',
		},
	],
	[
		{
			title: 'News & Events',
			link: '/news-events',
		},
		{
			title: 'About',
			link: '/about',
		},
	],
	[
		{
			title: 'Education',
			link: '/education',
		},
		{
			title: 'FAQ',
			link: '/faq',
		},
	],
	[
		{
			title: 'Digitization',
			link: '/digitization',
		},
		{
			title: 'Search',
			link: '/search',
		},
	],
	[
		{
			title: 'Licensing',
			link: '/licensing',
		},
		{
			title: 'Privacy Policy',
			link: '/privacy-policy',
		},
	],
];

export default function Footer() {
	/* const toTop = () => {
		//console.log('toTop')
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}; */

	const navigate = useNavigate();

	return (
		<footer className={`py-5 ${styles.footer}`}>
			<Container>
				<Row>
					<Col className={`${styles.supportUs}`} xl={3}>
						<div className={`${styles.suTitle}`}>Support US</div>
						<div className={`${styles.suDescription}`}>
							Help us preserve Texas' film heritage
						</div>
						<div className={`${styles.suButton}`}>
							<Button onClick={() => navigate('/support-us')}>
								Read More
							</Button>
						</div>
					</Col>
					<Col xl={3} className={`${styles.optionsCol}`}>
						{options.map((option, index) => (
							<Row key={index} className={`${styles.options}`}>
								<Col
									className={`${styles.option}`}
								//onClick={() => navigate(option[0].link)}
								>
									<Link to={option[0].link}
										className={`${styles.search}`}>
										{option[0].title}
									</Link>
								</Col>
								{option[1].title === 'Search' ? (
									<Col className={`${styles.option}`}>
										<a
											href={option[1].link}
											className={`${styles.search}`}
										>
											{option[1].title}
										</a>
									</Col>
								) : (
									<Col
										className={`${styles.option}`}
									//onClick={() => navigate(option[1].link)}
									>
										<Link to={option[1].link}
											className={`${styles.search}`}
										>
											{option[1].title}
										</Link>
									</Col>
								)}
							</Row>
						))}
					</Col>
					<Col className={`${styles.About}`}>
						<div className={`${styles.title}`}>
							Texas Archive of the Moving Image
						</div>
						<div className={`${styles.description}`}>
							<p>
								512-485-3073
								<br />
								info@texasarchive.org
								<br />
								3908 Avenue B, Suite 105
								<br />
								Austin, TX 78751
							</p>
						</div>
						<p className={`${styles.open}`}>
							Open by appointment only.
						</p>
					</Col>
					<Col
						className='d-flex flex-column align-items-center'
						xl={3}
					>
						<Button
							href='#main'
							className={`rounded-circle ${styles.btnUp}`}
						>
							<ArrowUp className={`${styles.icon}`} />
						</Button>
						<Button
							className={`${styles.btnMakeDonation}`}
							onClick={() => navigate('/support-us')}
						>
							<SuitHeartFill className={`${styles.icon}`} />
							Donate Now
						</Button>
						<Button
							className={`${styles.btnJoinMailing}`}
							onClick={() =>
								window.open(
									'https://texasarchive.us14.list-manage.com/subscribe?u=141d486eef7a04ce83876ba1b&id=0e8bd4a854',
									'_blank'
								)
							}
						>
							<EnvelopePaper className={`${styles.icon}`} />
							Join our mailing list
						</Button>
						<SocialOptions />
					</Col>
				</Row>
			</Container>
		</footer>
	);
}
