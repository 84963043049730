import React from 'react'
import { Button } from 'react-bootstrap'
import { SuitHeartFill } from 'react-bootstrap-icons'
import styles from './DonationBtn.module.scss'
import { useNavigate } from 'react-router-dom'

export default function DonationBtn() {
    const navigate = useNavigate();
    return (
        <Button
            className={`${styles.btnMakeDonation}`}
            onClick={() => navigate('/support-us')}
        >
            <SuitHeartFill className={`${styles.icon}`} />
            Donate Now
        </Button>
    )
}
