import React from 'react'
import { Accordion } from 'react-bootstrap'
import styles from './AccordionItext.module.scss'
import Itext from './Itext/Itext';

export default function AccordionItext(props) {
    const { itext, extra } = props;
    //TODO: el accordion item en un componente llamado itext generico para tm y con la logica de toc
    //Get the index of the last itext item that is not of type 'other'

    //count how many itext items are of type 'other' with a foreach loop
    //TODO: array.filter para filtrar los itext que no son de tipo other
    let otherCount = itext?.filter(item => item.type === 'other').length;

    //Order by weight itext 

    /* let otherCount = 0;
    itext.forEach(item => {
        if (item.type === 'other') {
            otherCount++;
        }
    }); */
    /* let lastItextIndex = 0;
    for (let i = 0; i < itext.length; i++) {
        if (itext[i].type !== 'other') {
            lastItextIndex = i;
        }
    } */
    let map = null;
    if (extra) {
        if (extra.kml_files) {
            map = extra.kml_files.result[0];
            if(map){
                map.type = 'map';
                map.title = 'Map';
            } else{
                map = null;
            }

        }
    }

    if (itext) {
        
        const sortedItextData = itext.sort((a, b) => b.weight - a.weight);
        return (
            <Accordion defaultActiveKey={(itext.length - 1) - otherCount} className={`${styles.accordion}`}>
                {
                    map && (
                        <Itext item={map} />
                    )
                }
                {
                    sortedItextData.map((item, index) => {
                        return (
                            <Itext item={item} index={index} key={index} />
                        )
                    })
                }
            </Accordion>
        )
    } else {
        return null;
    }
}
